.borderedTableOptimized, .borderedTableOptimized table, .borderedTableOptimized th, .borderedTableOptimized td {
    border: 1px solid rgba(177, 177, 177, 0.65);
  }

  .borderedTableOptimized table {
    width: '100%';
    background-color: rgb(213, 223, 237);
  }

  .outerTableOptimized {
    border-color: rgb(213, 223, 237);
    /* border-color: rgb(0, 21, 41); */
    /* border-color: #40a9ff;     */
    /* border-color: #40a9ff;     */
    border-style: solid;
    border-width: 4px;
  }

  .pagingTableOptimized {
    background-color: rgb(213, 223, 237);
  }


  .requiredSelect > div /*.ant-select-selection*/ {
    border-color: red !important;
  }