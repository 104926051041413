.isHidden {
    display: none;
}

.action_footer {
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: left;
}

.action_footer > button {
    margin-left: 8px;
} 

/*
.highlightOnFocus {
    border-color: black;
    border-style: 'solid'; 
    border-width: 0.5px;
}
*/
.highlightOnFocus:focus-within {
    border-color: orange;
    border-width: 2px;
}