.arrow_icon,
.arrowdown_icon {
    background-size: 100% 100%;
    width: 35px;
    height: 35px;
    margin-top: -35px;
    margin-left: -35px;
    float: left;
}

.arrow_icon {
    background-image:url(./skins/cust_order/arrow.png); 
}

.arrowdown_icon {
    background-image:url(./skins/cust_order/arrowdown.png);
}

.itkabel_icon,
.beahana_icon,
.car_icon,
.muhan_icon {
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
}

.itkabel_icon {  background-image:url(./skins/cust_order/itkabel.png);}
.beahana_icon {  background-image:url(./skins/cust_order/beahana.png);}
.car_icon { background-image:url(./skins/cust_order/carButGreen.png);}
.muhan_icon { background-image:url(./skins/cust_order/muhan.png);}

.step1_green,
.step2_green,
.step3_green,
.step1_red,
.step2_red,
.step3_red,
.step4_red,
.step2_gray,
.step3_gray,
.step4_gray,
.trans_green,
.trans_gray {
    background-size: 100% 100%;
    margin: 5px;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}

.step1_green { background-image:url(./skins/cust_order/status.png);}
.step1_red { background-image:url(./skins/cust_order/vlistRed.png);}

.step2_green {  background-image:url(./skins/cust_order/packing.png);}
.step2_red {  background-image:url(./skins/cust_order/arizaRed.png);}
.step2_gray {  background-image:url(./skins/cust_order/arizaGrey.png);}

.step3_green { background-image:url(./skins/cust_order/carGreen.png);}
.step3_red { background-image:url(./skins/cust_order/car.png);}
.step3_gray { background-image:url(./skins/cust_order/carGrey.png);}

.step4_red { background-image:url(./skins/cust_order/exceptRed.png);}
.step4_gray { background-image:url(./skins/cust_order/exceptGrey.png);}

.trans_green { background-image:url(./skins/cust_order/layer_38.png);}
.trans_gray { background-image:url(./skins/cust_order/layer_39_0.png);}

.progres_green,
.progres_red,
.progres_but { 
    background-size: 100% 100%;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}

.progres_green { background-image:url(./skins/cust_order/progressGreen.png);}
.progres_red { background-image:url(./skins/cust_order/progressRed.png);}
.progres_but { background-image:url(./skins/cust_order/progressBut.png);}

.cart_title th.ant-table-row-cell-break-word,
.logo_frame {
    background-image:url(./skins/cust_order/layer_31.png) !important;
    background-size: 100% 100% !important;
    color: white !important;
    margin: -4px;
}
