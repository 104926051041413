@import '~antd/dist/antd.css';

.ant-layout-header,
.ant-menu-dark,
.ant-layout-sider,
.page-toolbar,
#search_pages_div
 {
    background: #001529;
}


.App {
  text-align: right;
  direction: rtl;
  height: 100%;
}

#root, body {
  height: 100%;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.App .trigger:hover {
  color: #1890ff;
}


.App .menu-icon {
  margin-left: 16px;
}

.App .menu-submenu
{
  margin-right: 48px;
}

.ant-menu-submenu-arrow
{
  left: 16px !important;
  right: auto !important;
}

.ant-menu-submenu-title
{
  padding-right: 16px !important;
}

.App .logo
{
  color: aqua;
  /* margin: 0px 24px 16px 0; */
  float: left;
}

.ant-input-affix-wrapper input
{
  padding-right: 30px;
  padding-left: auto;
}

.ant-input-affix-wrapper .ant-input-prefix
{
  right: 12px;
  left: auto;
}

.ant-input-affix-wrapper .ant-input-suffix
{
  /* fix login password field */
  left: 12px;
  right: auto;
}

.login-form
{
  max-width: 300px;
  margin: 10px;
  background-color: white;
  padding: 10px;
  margin-right: auto;
  margin-left: auto;
}

.ant-btn-loading .anticon-loading
{
  margin-left: auto;
  margin-right: auto;
}

button.ant-btn-loading
{
  direction: ltr;
}

.ant-btn-loading span
{
  margin-left: 8px;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu
{
  float: right;
}

.ant-layout-wrapper
{
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.ant-layout-header
{
  flex-grow: 0;
  flex-shrink: 0;
}

.ant-layout-container
{
  flex-grow: 1;
}

.ant-layout-footer
{
  flex-grow: 0;
  flex-shrink: 0;
}

.footer-copyright
{
  text-align: left;
  display: block;
}

.form-instruction
{
  margin-bottom: 1.5em;
}

.ant-message
{
  direction: rtl;
}

.ant-message .anticon
{
  margin-left: 8px;
  margin-right: auto;
}

.logo
{
  font-style: italic;
  font-weight: 700;
  font-size: 2em;
  line-height: 64px;
}

.header-user-profile
{
  color: #fff;
  display: inline-block;
  min-width: 150px;
}

.header-user-profile .ant-avatar
{
  float: right;
  margin-left: 8px;
}

.header-user-profile-name
{
  line-height: 1em;
  font-weight: bold;
  margin-top: 0.5em;
}

.header-user-profile-logout
{
  font-size: 0.75em;
  line-height: 1em;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.header-user-profile-logout:hover
{
  text-decoration: underline;
}

.ant-select-selection-selected-value
{
  float: right !important;
}

.ant-select-dropdown-menu-item
{
    text-align: right !important;
    /*padding-right: 3em;*/
    padding-left: 2em;
}

.ant-select-dropdown-menu-item-group-title
{
    text-align: right !important;
}

.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24
{
    float: right !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 128px;
  height: 128px;
}

.ant-form-item-label
{
  text-align: right !important;
}

.data-entry-form
{
    columns: 2 auto;
    column-fill: balance;

}

.data-entry-form .ant-row
{
    page-break-inside: avoid;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;

}

.table-actions
{
    float: left;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td
{
  word-break: break-word !important;
  /*direction: ltr;*/
}
/*
.ant-table-tbody > tr > td {
    direction: ltr;
}
*/

td, th
{
    text-align: center;
}

.ant-popover-message-title
{
    direction: rtl;
}

.clickable
{
    cursor: pointer;
}

.margin-4
{
    margin: 4px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 4px 4px;
  word-break: break-all;
}

.ant-modal-footer {
  text-align: left;
}

.ant-modal-header {
  padding: 4px 4px;
}

.ant-modal-close {
  right: unset;
  left: 0px;
  color: #e6f7ff;
}

.ant-modal-close-x {
  font-size: 24px;
}

.ant-modal {
  top: 50px;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #40a9ff;
  text-decoration: none;
}

.ant-table-pagination.ant-pagination {
  margin-bottom: 8px;
  margin-top: 8px;
}

.ant-pagination-jump-prev:focus:after,
.ant-pagination-jump-prev:hover:after {
  content: "\E61F\E61F";
  /*content: "\E620\E620";*/
}

.ant-pagination-jump-next:focus:after,
.ant-pagination-jump-next:hover:after {
  content: "\E620\E620";
  /*content: "\E61F\E61F";*/
}

/*
.ant-pagination-prev .ant-pagination-item-link:after {
  content: "\E61F";
  display: none;
}

.ant-pagination-next .ant-pagination-item-link:after {
  content: "\E620";
  display: none;
}*/

.ant-pagination-prev:after {
  content: "\E623";
  display: none;

}
.ant-pagination-next:after {
  content: "\E623";
  display: none;
}

li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon:after{
  /**/
  display: none;
}
li.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon:after{
  /**/
  display: none;
}

.ant-pagination.mini .ant-pagination-options-quick-jumper {
  padding-right:8px
}

.ant-modal-content {
    direction: rtl;
    text-align: right;
}

.ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
    right: auto;
    left: 0;
}

.ant-input-search > .ant-input-suffix > .ant-input-search-button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-table {
  background-color: #fff;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
}

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
}

.ant-badge-multiple-words {
  direction: ltr;
}

.ant-table-column-sorters{
  /*direction: rtl;*/
  /*align-content: flex-start;*/
  display: flex;
  justify-content: center; /*flex-start*/
  
  /*background: #1890ff;*/
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters{
  padding-right: 0px !important;
}

.ant-table-thead > tr > th {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.ant-table-thead > tr > th .ant-table-column-sorter {
  left: 6px;
  right: unset;
}

.ant-table-pagination.ant-pagination {
  /*
  margin: 16px 0;
  */
  direction: ltr;
}

.ant-calendar-picker-clear, .ant-calendar-picker-icon {
  right: unset !important;  /* disable value */
  left: 12px; 
}

.ant-select-arrow {
  right: unset !important; 
  left: 11px;
}

.ant-select-dropdown.ant-select-dropdown--multiple 
.ant-select-dropdown-menu-item .ant-select-selected-icon {
  right: unset !important; 
  left: 12px;
}

.ant-time-picker-icon {
  right: unset !important; 
  left: 11px;
}

.ant-select-dropdown-menu-item-group-title {
  background-color: #eff5ff;
  font-weight: bold;
}

/* Graphs */
.antd-pro-charts-pie-pie .antd-pro-charts-pie-value {
  right: unset !important;
  left: 0 !important;
  direction: ltr !important;
}

.antd-pro-charts-pie-pie .antd-pro-charts-pie-total {
  max-height: unset !important;
  direction: rtl !important;
}

.pie-stat {
  direction: ltr !important;
}

.antd-pro-charts-pie-legend {
  direction: rtl !important;
}

.antd-pro-charts-pie-pie .antd-pro-charts-pie-dot {
  margin-right: unset !important;
  margin-left: 8px !important;
}
.antd-pro-charts-pie-pie .antd-pro-charts-pie-total > h4 {
  height: unset !important;
}

.antd-pro-charts-chart-card-total {
  direction: ltr !important;
}

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-action {
  right: unset !important;
  left: 0 !important;
}

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-metaWrap {
  float: right !important;
}

.ant-tooltip-inner {
  text-align: right !important;
  direction: rtl !important;
}

.chart-card-details {
  float: left;
  direction: rtl;
  text-align: unset;
}

.antd-pro-trend-trendItem {
  direction: ltr !important;
}

.dashboard .ant-calendar-picker-input  {
  text-align: center !important;
}

.dashboard .ant-popover-title {
  text-align: right !important;
}

.row_pink {
  background-color: pink;
}

.row_lightblue {
  background-color: lightblue;
}

.row_greenyellow {
  background-color: greenyellow;
}

.row_yellow {
  background-color: yellow;
}
/* 
#glassix-widget-launcher-container{
  left: 400px !important;
}

@media only screen and (max-width: 800px) {
  #glassix-widget-launcher-container{
    left: 2px !important;
  }
} */

.hide_search_icon .ant-input-search-icon {
  display: none !important;
}


@media only screen and (max-width: 600px) {
  .ant-layout-header
  {
    padding: 0px;
  }

  .header-user-profile {
    min-width: 100px;
  }

  .ant-calendar-range {
    width: 320px;
  }
  
  .ant-calendar-range-part {
      width: 100%;
  }
}
