	@font-face {
		font-family: "FbJoker";
		src: url("./FbJoker-Regular.otf");
	}

	@font-face {
		font-family: "FbTipograf";
		src: url("./FbTipograf-Regular.otf");
	}

	@font-face {
		font-family: "FbSpacer";
		src: url("./FbSpacer-Regular.otf");
	}

	@font-face {
		font-family: "FbEgotrip-Bold";
		src: url("./FbEgotrip-Bold.otf");
	}

	.logo_agala,
	.logo_agala_2,
	.agala_footer,
	.agala_footer_2,
	.logo_text,
	.menu_button,
	.menu_button_active,
	.item_header,
	.item_header2,
	.mobile_menu_button,
	.mobile_menu_div,
	.delivery_method_box,
	.img_item,
	.content_item,
	.choose_container,
	.price_item,
	#item_headers,
	.search_slide,
	.search_button,
	#telephone,
	#tel_icon,
	#tel_icon_2,
	#telephone_footer,
	#tel_icon_footer,
	#tel_icon_footer_2,
	#logo_header,
	#logo_footer,
	#knisa_icon,
	#knisa_icon_2,
	#user_image,
	#guest,
	#language_icon,
	#language_field

	/*, #slide_button */
		{
		display: inline-block;
		vertical-align: text-top;
	}

	.dinamic_box,
	.delivery_method_card,
	.title_block,
	.text_item,
	.text_item .ant-input,
	.text_item_input .ant-input,
	.text_item_input_password .ant-input,
	.sale_title,
	.search_field,
	.search_but_orange,
	.menu_button,
	.menu_button_active,
	#links_footer,
	#bg_sikum_title,
	#buy_but,
	#add_to_cart_but,
	#erase_but,
	#language_field,
	#telephone,
	#telephone_footer,
	#guest,
	.choose_container,
	.choose_container .ant-select,
	.choose_container .ant-select-selection {
		font-family: FbJoker, Arial, Helvetica, sans-serif;
	}

	.font_style_2 {
		font-family: FbSpacer, Arial, Helvetica, sans-serif !important;
	}

	#guest {
		font-size: 22px;
	}

	.logo_agala_2 {
		height: 95px;
		margin-right: 40px;
	}

	.logo_agala {
		background-image: url(./skins/index/logo_agala.png);
		background-repeat: no-repeat;
		height: 95px;
		width: 123px;
	}

	.agala_footer {
		background-image: url(./skins/index/agala_footer.png);
		background-repeat: no-repeat;
		width: 73px;
		height: 57px;
	}

	.agala_footer_2 {
		height: 57px;
		margin-right: 40px;
	}

	.logo_text {
		margin-top: -80px;
		margin-left: -20px;
		font-family: FbEgotrip-Bold;
		color: #FFFFFF;
		text-shadow: 5px 5px 3px rgba(73, 73, 74, 0.1);
	}

	#logo_header {
		/* width: 50%; */
		margin-top: 15px;
		padding-right: 50px;
		font-size: 132px;
		height: 130px;
	}

	#item_headers {
		margin-top: 50px;
		float: left;
	}

	.item_header {
		width: 165px;
		height: 70px;
		text-align: center;
	}

	#logo_footer {
		font-size: 80px;
		margin-top: 17px;
		color: #FFFFFF;
		text-shadow: 5px 5px 3px rgba(73, 73, 74, 0.1);
	}

	.standart_page {
		padding: 20%;
		padding-top: 70px;
		padding-bottom: 100px;
		text-align: center;
	}

	.dinamic_box {
		padding: 39px;
		background: #ffffff !important;
		font-size: 18px;
		text-align: justify;
		color: #404041;
		padding-top: 22px;
	}

	#delivery_method_div {
		background: #404041;
		padding: 31px;
		padding-bottom: 70px;
		text-align: center;
	}

	.delivery_method_box {
		padding: 39px;
	}

	.delivery_method_card {
		width: 335px;
		height: 192px;
		background: #ffffff;
		font-size: 43px;
		text-align: center;
		color: #404041;
		padding-top: 22px;
		cursor: pointer;
	}

	.delivery_method_circle {
		width: 87px;
		height: 88px;
		/* background: #f2b819;  */
		background: url(./skins/order_type/restaurant.png);
		border-radius: 50%;
		margin: auto;
		margin-bottom: 20px;
	}

	#d_method_icon_delivery {
		width: 78px;
		height: 41px;
		margin: auto;
		background: url(./skins/order_type/deliv_icon.png);
	}

	#d_method_icon_takeaway {
		width: 66px;
		height: 54px;
		margin: auto;
		background: url(./skins/order_type/isuf_icon.png);
	}

	#d_method_icon_sitting {
		width: 55px;
		height: 60px;
		margin: auto;
		background: url(./skins/order_type/icon_restaurant.png);
	}

	.title_block {
		height: 58px;
		background: #f3b919;
		font-size: 32px;
		text-align: center;
		color: #ffffff;
		padding: 16px;
	}

	.cust_registration .ant-modal-header {
		padding: 0px !important;
	}

	.padding_0 .ant-modal-header,
	.padding_0 .ant-modal-body {
		padding: 0px !important;
	}

	.cust_registration .ant-modal-body {
		/* padding: 30px !important; */
		margin-top: -6px;
		margin-bottom: -6px;
		background-color: #f1f1f1;
	}

	.cust_registration .ant-modal-footer {
		background-color: #f1f1f1;
		border-top: 0px;
	}

	.img_item {
		width: 27%;
		margin: 2%;
	}

	.content_item {
		width: 64%;
	}

	.text_item,
	.text_item .ant-input {
		font-size: 26px;
		text-align: right;
		color: #595a5a;
	}

	.text_item_input .ant-input {
		font-size: 26px;
		text-align: left;
		color: #595a5a;
	}

	.text_item_input_password .ant-input {
		font-size: 26px;
		color: #595a5a;
	}

	.text_item h2 {
		font-size: 30px;
		font-weight: 800;
	}

	.item_list_view {
		background: #ffffff;
		border: 1px solid #404041;
		border-radius: 5px;
		height: 50px;
	}

	.price_item {
		/* float: right; */
		color: red;
		font-size: 20px;
		font-weight: 500;
		/* margin-top: -10px; */
		font-family: Arial, Helvetica, sans-serif;
	}

	.price_item b {
		font-size: 30px;
		font-weight: 900;
	}

	.price_item sup {
		font-size: 15px;
		font-weight: 300;
		vertical-align: text-top;
	}

	.collapse_icon,
	.collapse_icon_active {
		float: left;
		height: 25px;
		width: 25px;
		margin-top: -20px;
	}

	.collapse_icon {
		background: url(./skins/food/arrow_ch3.png);
		/* margin-top: -50px; */
	}

	.collapse_icon_active {
		background: url(./skins/food/arrow_ch1.png);
	}

	.extra_checkbox {
		width: 20px;
		height: 20px;
		border: #606161 solid 1px;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.extra_checkbox_checked {
		width: 20px;
		height: 20px;
		display: inline-block;
		background: #f3b919;
		color: #ffffff;
		font-size: 16px;
		cursor: pointer;
		text-align: center;
	}

	.input_suffix_close .ant-input-suffix {
		right: auto !important;
		left: 12px !important;
	}

	#footer {
		width: 100%;
		height: 125px;
		background: #383838;
		border-top: #404041 solid 5px;
	}

	#links_footer {
		top: 0px;
		left: 0px;
		width: 94px;
		height: 69px;
		font-size: 20px;
		text-align: right;
		color: #ffffff;
	}

	#arrow_right,
	#arrow_left {
		width: 26px;
		height: 26px;
		margin: auto;
		cursor: pointer;
	}

	#arrow_right {
		background: url(./skins/index/arrow_right.png);
	}


	#arrow_left {
		background: url(./skins/index/arrow_left.png);
	}

	.sale_title {
		width: 100%;
		height: 46px;
		font-size: 33px;
		text-align: center;
		color: #ffffff;
	}

	#slider_pic {
		/* position: absolute !important; */
		width: 100%;
		/* height: 350px;  */
		/* margin-top: -50px; */
		/* background:url(./skins/index/slider_pic.png); */
	}

	#main_menu {
		/* top: 140px;
	left: 0px;
	width: 1921px; */
		height: 49px;
		border-bottom: #000000 solid 1px;
		text-align: center;
	}

	.search_slide,
	.search_button {
		padding: 8px;
	}

	.search_field {
		/* top: 0px;
	left: 0px; */
		width: 235px;
		height: 29px;
		background: #ffffff;
		font-size: 14px;
		text-align: right;
		color: #a6a8ab;
		padding-right: 10px;
		padding-top: 3px;
	}

	.search_but_orange {
		width: 95px;
		height: 29px;
		background: url(./skins/index/search_but_orange.png);
		font-size: 17px;
		text-align: right;
		color: #ffffff;
		padding-right: 27px;
		padding-top: 3px;
		cursor: pointer;
	}

	.menu_button,
	.menu_button_active {
		width: 140px;
		height: 47px;
		padding: 7px;
		font-size: 19px;
		text-align: center;
		margin-top: -1px;
		cursor: pointer;
	}

	.menu_button {
		background: #e6e7e8;
		color: #595a5a;
	}

	.menu_button_active {
		/* background:#f3b919; */
		color: #ffffff;
	}

	.ant-popover {
		z-index: 3000;
	}

	#cart_shop,
	#cart_shop_from_pos,
	#cart_shop_end {
		background: #f1f1f2;
		color: #595a5a;
	}

	#cart_shop {
		position: fixed !important;
		top: 250px;
		left: 2%;
		width: 25%;
		/* z-index: 2000; */
	}

	#cart_shop_from_pos {
		position: fixed !important;
		top: 180px;
		left: 0px;
		width: 27%;
		/* z-index: 2000; */
	}

	#cart_shop .ant-card-head,
	#cart_shop_from_pos .ant-card-head {
		padding: 0px !important;
	}

	#header_cart_2 {
		font-family: FbSpacer, Arial, Helvetica, sans-serif;
		font-size: 25px;
		text-align: right;
		color: #010101;
		padding-right: 30px;
		padding-left: 30px;
	}

	#btn_header_cart_2 {
		width: 297px;
		height: 44px;
		background: #ed1c24;
		text-align: center;
		color: #ffffff;
		margin: auto;
		margin-bottom: 15px;
		border-radius: 10px;
		cursor: pointer;
	}

	#footer_cart_2 {
		font-family: FbSpacer, Arial, Helvetica, sans-serif;
		font-size: 25px;
		text-align: center;
		color: #010101;
		padding-right: 30px;
		padding-left: 30px;
	}

	.btn_footer_cart_2 {
		width: 297px;
		height: 44px;
		text-align: center;
		margin: auto;
		font-weight: bold;
		margin-bottom: 15px;
		border-radius: 10px;
		cursor: pointer;
	}


	#bg_sikum_title {
		width: 276px;
		height: 42px;
		font-size: 25px;
		text-align: center;
		color: #ffffff;
		margin-top: -4px;
	}

	#buy_but,
	#add_to_cart_but,
	#erase_but {
		width: 90%;
		height: 54px;
		font-size: 21px;
		text-align: center;
		padding: 10px;
		margin: auto;
		color: #ffffff;
		background: #f3b919;
	}

	#erase_but {
		background: #808184;
	}

	#add_to_cart_but {
		width: 184px;
	}


	.cart_but,
	.cart_but_yellow,
	.cart_style_6,
	#recycle_bin_but {
		width: 37px;
		height: 37px;
		float: right;
	}

	.cart_but {
		background: url(./skins/index/cart_but.png);
	}

	.cart_but_yellow {
		background: url(./skins/index/cart_but_yellow.png);
	}

	.cart_style_6 {
		background: url(./skins/index/cart_style_6.png);
		background-size: 100%;
		background-repeat: no-repeat;
	}

	#recycle_bin_but {
		background: url(./skins/index/recycle_bin_but.png);
	}

	#header {
		font-size: 30px;
		/* color: #ffffff; */
		padding: 6px;
		padding-left: 25px;
		/* height: 163px; */
		/* from server */
		margin-top: -18px;
		/* ***** */
		/* background:#404041; */
	}

	#knisa_icon,
	#knisa_icon_2,
	#user_image {
		/* top: 59px !important;
	position: absolute !important;
	left: 458px; */
		width: 60px;
		height: 61px;
		cursor: pointer;
	}

	#knisa_icon {
		background: url(./skins/index/knisa_icon.png);
	}

	#knisa_icon_2 {
		background: url(./skins/index/knisa_icon_2.png);
	}

	#tel_icon,
	#tel_icon_2 {
		margin-top: 18px;
		margin-left: 5px;
	}

	#tel_icon_footer {
		margin-top: 35px;
	}

	#tel_icon,
	#tel_icon_footer {
		width: 29px;
		height: 29px;
		background: url(./skins/index/tel_icon.png);
	}

	#tel_icon_2 {
		width: 29px;
		height: 29px;
		background: url(./skins/index/tel_icon_2.png);
	}

	#tel_icon_footer_2 {
		width: 20px;
		height: 20px;
		background: url(./skins/index/tel_icon.png);
	}


	#language_icon {
		/* top: 70px !important;
	position: absolute !important;
	left: 155px; */
		width: 33px;
		height: 33px;
		margin-left: 10px;
		background: url(./skins/index/language_icon.png);
	}


	#language_field {
		width: 107px;
		height: 28px;
		border-radius: 5px;
		background: #ffffff;
		font-size: 14px;
		text-align: right;
		color: #a6a8ab;
		cursor: pointer;
	}

	#slide_button {
		/* position: absolute !important;
	left: 45px; */
		width: 13px;
		height: 13px;
		/* z-index: 10000; */
		/* margin-top: 10px; */
		background: url(./skins/index/slide_button.png);
	}

	#telephone_footer {
		margin-top: 20px;
		margin-right: 25px;
	}

	#telephone,
	#telephone_footer {
		font-size: 25px;
		text-align: right;
		color: #ffffff;
		direction: ltr;
	}

	#social-icon-links {
		position: absolute !important;
		top: 250px;
		left: 0px;
		width: 40px;
	}

	.cart_erase_icon,
	.cart_plus_icon,
	.cart_edit_icon_box {
		width: 22px;
		height: 22px;
		float: left;
		margin: 2px;
		cursor: pointer;
	}

	.cart_erase_icon {
		background: url(./skins/cart/list_erase_icon.png);
	}

	.cart_plus_icon {
		background: url(./skins/cart/list2.png);
	}

	.cart_edit_icon {
		background: url(./skins/cart/pen.png);
		width: 14px;
		height: 14px;
		margin: 4px;
	}

	.cart_edit_icon_box {
		background: url(./skins/cart/bluekolo.png);
	}

	.desctop_block {
		display: block;
	}

	.mobile_block {
		display: none;
	}

	.mobile_menu_button {
		width: 20%;
		height: 48px;
		font-size: 30px;
		color: #404041;
	}

	.mobile_menu_div {
		width: 80%;
	}

	.cart_icon_fixed_bottom {
		height: 120px;
		width: 120px;
		border-radius: 50%;
		font-size: 70px;
		padding-right: 5px;
		color: #ffffff;
		background-color: #f3b919;
		margin-top: 20px;
		position: fixed;
		bottom: 20px;
		left: 20px;
		text-align: center;
		cursor: pointer;
	}

	.choose_container {
		margin: 17px;
	}

	.choose_container .ant-select-selection {
		padding: 10px;
	}

	.choose_container,
	.choose_container .ant-select,
	.choose_container .ant-select-selection {
		width: 340px !important;
		height: 50px !important;
		font-size: 22px !important;
		text-align: right;
		color: #5a5b5b;
	}


	.choose_container_b_selector,
	.choose_container_b_selector .ant-select,
	.choose_container_b_selector .ant-select-selection {
		width: 340px !important;
		height: 70px !important;
		font-size: 22px !important;
		text-align: right;
		color: #5a5b5b;
	}

	.choose_container_type_6,
	.choose_container_type_6 .ant-select,
	.choose_container_type_6 .ant-select-selection {
		width: 100% !important;
		height: 70px !important;
		font-size: 22px !important;
		text-align: right;
		color: #5a5b5b;
	}

	.width_600,
	.width_600 .ant-select,
	.width_600 .ant-select-selection {
		width: 600px !important;
	}

	.width_full,
	.width_full .ant-select,
	.width_full .ant-select-selection {
		width: 95% !important;
	}

	.google-places-autocomplete {
		display: inline-block !important;
		width: 95% !important;
	}

	.item_sale_bargains {
		background-image: url(./skins/index/bargains_icon.png);
		width: 32px;
		height: 32px;
		float: left;
	}

	.scrollmenu {
		overflow: auto;
		white-space: nowrap;
	}

	.over_item {
		cursor: url(./skins/index/add-to-cart-over-item.png), auto;
	}

	.header_style_4 {
		background-image: url(./skins/index/header_BG.png);
		background-size: 100%;
		background-repeat: no-repeat;
	}

	.header_style_5 {
		background: #fff5e9;
	}

	.background_style_6 {
		background-image: url(./skins/index/style_6_BG.jpg);
		background-size: 100%;
		background-repeat: no-repeat;
	}

	.background_style_6_pay {
		background-image: url(./skins/index/pay_BG_style_6.JPG);
		background-size: 100%;
		background-repeat: no-repeat;
	}


	.modal_style_4 .ant-modal-content {
		background-image: url(./skins/index/modal_style_4.png);
		padding: 50px;
		padding-top: 0px;
	}

	.modal_style_5 .ant-modal-content {
		background-image: url(./skins/index/modal_style_5.png);
		padding: 50px;
		padding-top: 0px;
	}

	.border_text_block {
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #000000;
	}


	@media only screen and (max-width: 1000px) {
		#logo_header {
			padding-right: 10px;
			width: 100%;
		}

		#item_headers {
			/* width: 50%; */
			height: auto;
			margin-top: 0px;
			/* float: none; */
			/* text-align: left; */
		}

		.item_header2 {
			width: "50%";
		}

		.item_header {
			/* width: auto; */
			margin: 3px;
			height: 50px;
		}

		#telephone {
			font-size: 25px;
			margin-top: 18px;
		}

		.standart_page{
			padding: 5px;
			padding-top: 70px;
			padding-bottom: 70px;
		}
	}

	@media only screen and (max-width: 700px) {
		.logo_text {
			display: block;
			font-size: 70px;
			margin-top: -60px;
		}

		.logo_agala,
		.logo_agala_2 {
			/* display: block;  */
			max-width: 100%;
			margin-top: -20px;
			margin-right: 0px;
		}

		.agala_footer,
		.agala_footer_2 {
			margin-top: -20px;
			margin-right: 40px;
		}

		#header {
			padding-left: 0px;
			/* height: 250px; */
			margin-top: 0px;
		}

		.desctop_block,
		.sale_title {
			display: none;
		}

		.mobile_block {
			display: block;
		}

		.menu_button,
		.menu_button_active {
			display: block;
			width: 100%;
			height: 49px;
		}

		#main_menu {
			height: auto;
		}

		#logo_footer {
			width: 70%;
		}

		#logo_header {
			height: 80px;
		}

		#cart_shop,
		#cart_shop_from_pos {
			position: static !important;
			width: 96%;
		}

		.img_item,
		.content_item {
			width: 96%;
		}

		.choose_container {
			display: block;
			width: 95%;
		}

		#telephone_footer {
			font-size: 14px;
		}

		#delivery_method_div {
			padding: 5%;
		}

		.delivery_method_box {
			padding: 3%;
			width: 100%;
		}

		.delivery_method_card {
			width: 100%;
		}

		.modal_style_4 .ant-modal-content ,
		.modal_style_5 .ant-modal-content {
			padding: 10px;
			padding-top: 0px;
		}




	}