body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.padding_0 .ant-modal-header,
	.padding_0 .ant-modal-body {
		padding: 0px !important;
	}
