.ant-card-head-title {
    padding: 4px 0;
}
.ant-card-head {
    min-height: 4px;
}

.ant-form-vertical .ant-form-item {
    padding-bottom: 0px;
}

.ant-card-body {
    padding: 8px;
}

