#frame-title {
    /* height: 50px;  */
     width: 100%;  
    /* padding:5px; */
    display:inline-block;
    overflow: hidden;
    position: relative;
    direction: rtl;
}
#frame-title div div{
    width: 16%;
    display: inline-block;
    position: relative;
    animation-name: frameTitle;
    animation-duration: 3s;
}
@keyframes frameTitle {
    0%  {  left:16% }
    100% {  left:0px }
}
#frame-title-normal{ 
    direction: rtl; 
}

#frame-title-mobile{
    height: 50px; 
    width: 100%; 
    padding:5px;
    display:inline-block;
     overflow: hidden;
     position: relative;
     direction: rtl;
}

#frame-title-mobile h3 {
    font-size: 15px;
    position: absolute;
    color: #40a9ff;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    white-space: nowrap;
    animation: frameTitleMobile 40s linear infinite;
}

@keyframes frameTitleMobile {
    0%   {  transform: translateX(-100%); }
    100% {  transform: translateX(2700px); }
}

