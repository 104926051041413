
/* module */
/*
:local(.mainTable) {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    background: red;
} 
*/

:local(.mainTable) {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    background: red;
} 